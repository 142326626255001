@import '../core/index';

.flyout-nav {
  background: $white;
  bottom: 0;
  box-shadow: 0 0 25px -5px rgba($black, 0.5);
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  padding: toRem(36 36 108);

  .flyout-open & {
    transform: translateX(0);
    transition: transform 500ms ease-in-out;

    @media (media(m-min)) {
      transform: translateX(484px);
    }
  }

  .admin-bar & {
    height: calc(100vh - 32px);
    top: toRem(32);
  }

  &__close {
    background: none;
    border: none;
    color: $color-gray-alpha-10;
    cursor: pointer;
    font-family: $font-roboto;
    font-size: toRem($body-text-size);
    margin-left: toRem(-17);
    position: absolute;

    .icon-times {
      height: toRem($body-text-size);
      width: toRem($body-text-size);
    }
  }

  &__logo {
    margin: toRem(-10) auto toRem(36);
    max-width: calc(100% - 108px);
    text-align: center;

    a {
      display: inline-block;
    }

    img {
      margin: 0 auto;
      max-height: toRem(40);
      width: auto;
    }
  }
}

.flyout-nav__menu {

  &--upper,
  &--lower {
    border-bottom: 1px solid rgba( $color-black, 0.1 );
    margin: toRem(0 -36);
    padding: toRem(0 36 20);
  }

  &--lower {
    padding-top: toRem(20);

    .flyout-nav__menu-item {
      padding: toRem(8 5);
    }
  }

  &-item {
    color: $color-gray-alpha-10;
    display: block;
    font-family: $font-arthouse;
    font-size: toRem(14);
    line-height: 1.2;
    padding: toRem(7 5);
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    &.parent-item {
      font-size: toRem(16);
      font-weight: 700;
      padding-top: toRem(20);
    }
  }
}

.flyout-nav__search {
  margin-bottom: toRem(10);

  form {
    align-items: center;
    background-color: $color-gray-01;
    border: 0;
    border-radius: 20px;
    display: flex;
    flex-direction: row-reverse;
    height: toRem(35);
    justify-content: space-between;
    padding: toRem(0 12 0 23);

    &:focus,
    &:focus-within {
      box-shadow: 0 0 7px 0 rgba($color-blue-19, 0.6);
      outline: none;
    }
  }

  label {
    width: calc(100% - 28px); // Width minus submit and space.
  }

  .search-submit {
    appearance: none;
    background-color: transparent;
    background-image: url('../../images/icons/icon-search-dark.svg');
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    color: transparent;
    cursor: pointer;
    height: 18px;
    transition: opacity 0.3s;
    width: 18px;

    &:hover {
      opacity: 0.7;
    }
  }

  input[type=search] {
    -webkit-appearance: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: $color-gray-alpha-10;
    font-size: em(15);
    height: toRem(35);
    padding: toRem(7 10 7 0);
    width: 100%;

    &::placeholder {
      color: $color-gray-27;
    }

    &:focus {
      outline: none;
    }
  }
}

.flyout-nav__social {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: toRem(20);

  &-heading {
    color: $color-gray-12;
    font-size: toRem(12);
    text-transform: uppercase;
  }

  &-list {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    svg {
      color: $color-gray-alpha-10;
    }
  }
}

.flyout-contact-button {
  align-items: center;
  background: $color-blue-05;
  border: 0;
  bottom: 0;
  color: $color-white;
  display: flex;
  font-family: $font-arthouse;
  font-size: toRem(12);
  font-weight: 700;
  justify-content: center;
  padding: toRem(16);
  text-decoration: none;
  text-transform: uppercase;

  &:hover,
  &:focus {
    background: darken( $color-blue-05, 10% );
  }

  .flyout-open & {
    transform: translateX(0);
    transition: transform 500ms ease-in-out;

    @media (media(m-min)) {
      transform: translateX(484px);
    }
  }
}
